.LearningModuleDirectoriesCard {
    font-size: 3.8vmin;
    text-align: center;
    color: white;
    margin-top: .5%;
    margin-bottom: 6%;
    margin-left: 8.5%;
    margin-right: -2%;
    background-color: #127ebf!important;
    width: 40%;
    height: 25vmax;
    text-decoration: none;
}

@media screen and (min-width: 1000px) {
    .LearningModuleDirectoriesCard {
        font-size: 1.8vmax;
        text-align: center;
        color: white;
        margin-top: .5%;
        margin-bottom: 3%;
        margin-right: .00001%;
        margin-left: 2%;
        background-color: #127ebf!important;
        width: 18%;
        height: 20vmax;
        text-decoration: none;
    }
}

.LearningModuleDirectoriesCard:hover {
    background-color: #93c2ec !important;
}

.LearningModulesDirectoriesHeader {
    font-family: Lato, sans-serif;
    margin-top: 3%;
    margin-bottom: 2%;
}


.LearningModuleDirectoriesCardFont {
    color: white;
    font-family: Lato, sans-serif;
    text-decoration: none;
}


.LearningModulesDirectories {
    font-family: Lato, sans-serif;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}

.LearningModulesDirectoriesDashboard {
    margin-bottom: 3%;
    margin-right: 1.5%;
}

.card-custom {
    overflow: hidden;
    min-height: 450px;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  }
  
  .card-custom-img {
    height: 200px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-color: inherit;
  }
  
  /* First border-left-width setting is a fallback */
  .card-custom-img::after {
    position: absolute;
    content: '';
    top: 161px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 40px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 545px;
    border-left-width: calc(575px - 5vw);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
  }
  
  .card-custom-avatar img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    position: absolute;
    top: 100px;
    left: 1.25rem;
    width: 100px;
    height: 100px;
  }

  .card-custom-img-2 {
    background-image: url(http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg);
  }
  
  .card-body-2 {
    overflow-y: auto;
  }

  .card-footer-2 {
    background: inherit; 
    border-color: inherit;
  }
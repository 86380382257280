.x-markImage {
    width: 2vmin; 
    margin-left: -1%;
}

.checkmarkImage {
    width: 2vmin;
    margin-left: -1%;
}

.correctResults {
    font-family: Lato,sans-serif;
}

.wrongResults{
    font-family: Lato,sans-serif;
}

.otherAnswers {
    font-family: Lato,sans-serif;
}



.resultsCard {
    border: none;
    margin-bottom: 3%;
    background-color: white;
}

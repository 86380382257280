.invalid_bodyForm {
    background-image: url("registerbg.png");
    position:absolute;
    top:-400px;
    right:0px;
    bottom:0px;
    left:0px;
    animation: invalid_delay 1s linear
}

.invalid_bodyCol {
    width: 50%;
    padding-bottom: 30px;
    margin-top: 650px;
    margin-left: auto;
    margin-right: auto;
    background-color: aliceblue;
    justify-content: center;
}

.invalid_headerRow {
    text-align: center;
    align-items: center;
}

.invalid_headerRow2 {
    text-align: center;
    align-items: center;
}

.invalid_lockImg {
    width: 20%;
    height: 20%;
    margin: auto;
}

.invalid_redirectButton {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 70%;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: #cc3333;
    font-size: x-large;
}

.invalid_messageDiv {
    border-radius: 10px;
    background-color: rgb(190, 197, 202);
    margin: auto;
    width: 500px !important; 

}

.invalid_h1 {
    text-align: center;
    margin-bottom: 2%;
    font-size: 110%;
}

.invalid_h5 {
    margin-top: 10px;
    font-size: 150%;
}

@keyframes invalid_delay { 
    0% { opacity: 0%; } 
    70% { opacity: 0%; }
    100% { opacity: 100%; }
}
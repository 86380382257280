.Employee_Invite_Request_Card {
    margin-top: .5%;
    margin-bottom: .5%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 10px 10px 10px 10px;
    background-color: #d8dae3;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 90%;
    color: black;
}

.margin_bottom_employer {
    margin-bottom: 3.5%;
}

@media screen and (min-width: 700px) {
    .margin_bottom_employer {
        margin-bottom: 1.5%;
    }
}

.EmployerInviteRequests {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 14%;
    width: 90%;
}

.register_employee_header {
    margin-top: 3%;
    margin-left: 3%;
    font-size: 130%;
    text-shadow: 2px 2px 5px gray;
}

@media screen and (min-width: 700px) {
    .register_employee_header {
        margin-top: 3%;
        margin-left: 3%;
        font-size: 200%;
        text-shadow: 2px 2px 5px gray;
    }
}

.empolyee_invite_subtitle {
    margin-top: .1%;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 100%;
    width: 90%;
}

@media screen and (min-width: 700px) {
    .empolyee_invite_subtitle {
        margin-top: .1%;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 130%;
        width: 96%;
    }
}

.email_employee_invite {
    width: 90%;
    margin-top: 3%;

}

.invite_employees_bttn {
    width: 85%;
    font-family: Lato,sans-serif;
    margin-top: 3%;
    margin-bottom: 2%;
}

.Employer-container {
    background-color: #93c2ec;
    margin-top: 9%;
    width: 100%;
    
}


@media screen and (min-width: 1000px) {
    .Employer-container {
        background-color: #93c2ec;
        margin-top: 2%;
        margin-bottom: 0%;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

.employer-header_admin_dash {
    color: white;
    text-align: left !important;
    text-shadow: 2px 2px 5px black;
   
}

.EmployerCardValues_container  {
    width: 100%;
}

.EmployerCardValues {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 2%;
    margin-right: .5%;
    font-size: 80%;
    color:black;
}

@media screen and (min-width: 1000px) {
    .EmployerCardValues {
        margin-top: 1%;
        margin-bottom: 1%;
        margin-left: 2%;
        margin-right: .5%;
        font-size: 110%;
        color:black;
    }
}

.EmployerCard {
    margin-top: .7%;
    margin-left: 1%;
    margin-right: .5%;
    margin-bottom: .9%;
    border-radius: 10px 10px 10px 10px;
    background-color: #f1f1f1;
    width: 98%;
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 90%;
    color: white;
}


.EmployerCardValues_email {
    color: black;
    margin-top: 2%;
    margin-left: 0%;
    font-size: 90%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}
@media screen and (min-width: 1000px) {
    .EmployerCardValues_email {
        color: black;
        margin-top: 2%;
        margin-left: 20%;
        font-size: 90%;
        width: 100%;
        overflow:hidden !important;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
    }
}

.EmployerCardValues_username {
    color: black;
    margin-top: 2%;
    font-size: 90%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

@media screen and (min-width: 1000px) {
    .EmployerCardValues_username {
        color: black;
        margin-top: 2%;
        margin-left: 0%;
        font-size: 90%;
        width: 100%;
        overflow:hidden !important;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
    }
}

.EmployerCardValues_company {
    color: black;
    margin-top: 2%;
    font-size: 90%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

.EmployerInRowButton_admin_remove_user {
 background-color: #cc3333;
}
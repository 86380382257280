.dashlb_viewFullButton {
    width: 75%;
    height: auto;
}

.dash-container {
    background: #2c3e50;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #3498db, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #3498db, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}

.LeaderboardCard {
    font-size: 1.3vmax;
    text-align: center;
    color: rgb(255, 255, 255);
    height: 16vmax;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.userProfilesDash { 
    margin-bottom: 1%;
    width: .1%;
    height: .1%;
}

.dashLeaderboardFont {
    color: white;
    font-family: Lato,sans-serif;
    text-decoration: none;
}

.dashLeaderboardFont:hover {
     color: #d8dae3;
}

.userProfileDash {
    width: 4%;
    height: 4%;
}

.categoryDash {
    font-size: .1vmax;
    text-align: center;
    color: #127ebf;
}

.progressBarDash {
    width: 4%;
    height: 4%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
    align-items: center;
    display: flex;
    flex: column;
    justify-content: center;
}

.textDash {
    align-items: center;
    display: flex;
    flex: column;
    justify-content: center;
}
.learningModuleBg {
    background-color: #ffffff;
    animation: dash_fadeIn 1s;
    height: 120vh;
}

@media screen and (min-width: 1000px) {
    .learningModuleBg {
        background-color: #ffffff;
        animation: dash_fadeIn 1s;
        height: 90vh;
    }
}

.moduleName {
    color: #1e5b88;
    font-family: 'Segoe UI', sans-serif;
    font-size: 250%;
}

.moduleImage {
    margin-top: 1%;
    margin-bottom: 1%;
}

.moduleSubtitle {
    color: #127ebf;
    font-family: 'Segoe UI', sans-serif;
    font-size: 150%;
    padding-bottom: 15px;
}

.moduleDescription {
    color: black;
    border-radius: 25px;
    background-color: rgba(240, 255, 255, 0.495);
    margin-left: 1%;
    margin-right: 1%;
    font-family: Lato, sans-serif;
    font-size: 100%;
    padding: 1.5%;
    line-height: 1.8
}

@media screen and (min-width: 700px) {
    .moduleDescription {
        color: black;
        border-radius: 25px;
        background-color: rgba(240, 255, 255, 0.495);
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 1%;
        font-family: Lato, sans-serif;
        font-size: 130%;
        padding: 1.5%;
        line-height: 1.8
    }
}

.goToQuizBttn {
    width: 100%;
    font-size: 100%;
    margin-top: 1%;
    margin-bottom: 4%;
    background-color: #127ebf;
    font-family: Lato,sans-serif;
}

@media screen and (min-width: 700px) {
    .goToQuizBttn {
        width: 100%;
        font-size: 150%;
        margin-top: 1%;
        background-color: #127ebf;
        font-family: Lato,sans-serif;
    }
}
.goToQuizBttn:hover {
    color: #d8dae3;
    background-color: #127ebf;
}

.goToQuizBttn:focus {
    border: 0;
    background-color: #127ebf;
    box-shadow: none !important;
}


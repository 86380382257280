.navPills {
    background-color: #127ebf;
    color: white;
    margin-right: 1vmax;
    margin-left: 0vmax;
    margin-top: .5vmax;
    margin-bottom: .5vmax;
    font-size: 1vmax; 
}

.navbar-color {
    background: #2c3e50;  /* fallback for old browsers */

}



.navbar-white {
    color : white;
}

.content_img{
    position: relative;
    width: 200px;
    height: 100px;
    float: left;
}

.menubarFont {
    color: white !important;
    font-family: Lato,sans-serif;
}

.menubarFont:hover {
    color: #d8dae3;
}



.logo {
    border: white;
}

.pillz {
    border: none;
    background: none;
}

.mr-5 {
    margin-right: 5vmax;
}

.search-btn-color {
    background-color: #8f2929;
    color: white;
}

.search-btn-color:hover {
    background-color: white;
    color: #8f2929;
}

.dropdown-toggle::after {
    display: none !important; 
  }

.vertical-line {
    border-left: 1px solid rgb(175, 175, 175);
    height: 2vmax;
    align-self: center;
}
.sidebar-content {
    margin-left: 1%;
}
  




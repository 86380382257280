
.employee_row {
    width: 100%;
    height: 100%;

}

.EmployerJoinRequests {
    background-color: #d8dae3;
    color: black;
    width: 100%;
    margin-top: .5%;
    margin-bottom: .5%;
}

@media screen and (min-width: 700px) {
    .EmployerJoinRequests {
        background-color: #d8dae3;
        color: black;
        width: 100%;
        margin-top: 0%;
        margin-bottom: .5%;
    }
}

.EmployeeCard_dash {
    margin-top: .5%;
    margin-left: 2%;
    margin-right: .5%;
    margin-bottom: 1%;
    border-radius: 10px 10px 10px 10px;
    background-color: #1e5b88;
    width: 95%;
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 90%;
    color: white;
}

.titleFont_remove_users {
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 2.5%;
    text-shadow: 2px 2px 5px black;
}

@media screen and (min-width: 700px) {
    .titleFont_remove_users {
        margin-top: 1%;
        margin-bottom: 1%;
        margin-left: 2.5%;
        text-shadow: 2px 2px 5px black;
    }
}

.employee_remove_card_header {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 2.5%;
    font-size: 130%;
    text-shadow: 2px 2px 5px gray;
}

@media screen and (min-width: 700px) {
    .employee_remove_card_header {
        margin-top: .5%;
        margin-bottom: .5%;
        margin-left: 1.5%;
        font-size: 200%;
        text-shadow: 2px 2px 5px gray;
    }
}

.EmployeeCardHeader {
    margin-top: 0%;
    margin-right: 3%;
    margin-bottom: .1%;
    margin-left: 1%;
    width: 98%;
    color: black;
}

.employee_remove_email {
    width: 100%;
    font-size: 60%;
}

.employee_remove_username {
    width: 100%;
    margin-left: 0%;
    font-size: 60%;
}

.employee_remove_active {
    width: 100%;
    margin-left: 0%;
    font-size: 60%;
}

.employee_remove_progress {
    width: 100%;
    margin-left: 0%;
    font-size: 60%;
}

.employee_remove_bttn{
    width: 100%;
    font-size: 60%;
    margin-left: 0%;
}


@media screen and (min-width: 700px) {
    .employee_remove_email {
        font-size: 120%;
    }
}

@media screen and (min-width: 700px) {
    .employee_remove_username {
        font-size: 120%;
        margin-left: 20%;
    }
}

@media screen and (min-width: 700px) {
    .employee_remove_active {
        font-size: 120%;
        margin-left: 20%;
    }
}

@media screen and (min-width: 700px) {
    .employee_remove_progress {
        margin-left: 10%;
        font-size: 120%;
    }
}

@media screen and (min-width: 700px) {
    .employee_remove_bttn {
        font-size: 120%;
    }
}

.EmployeeCardValues {
    margin-top: 2%;
    margin-left: 10%;
    font-size: 90%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

@media screen and (min-width: 1000px) {
    .EmployeeCardValues {
        margin-top: 2%;
        font-size: 120%;
    }
}

.EmployeeInRowButton {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 0%;
}

@media screen and (min-width: 1000px) {
    .EmployeeInRowButton {
        margin-top: 1%;
        margin-bottom: 1%;
        margin-left: 45%;
    }
}

.InviteSubtitle {
    font-size: 1vmax;
}


.EmployeeInRowButton_confirm  {
    margin-top: 2%;
    width: 100%;
}

.EmployeeInRowButton_reset {
    margin-left: 1%;
}

.WelcomePanelCard {
    font-family: Lato,sans-serif;
    color: white;
    background-color: #1e5b88 !important;
    margin-top: .5%;
    margin-bottom: .5%;
    margin-left: 1%;
    margin-right: 1%;
    width: 100%;
    height: 100%;
}

.titleFont {
    font-family: Lato,sans-serif;
    font-size: 2.4vmax;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 6.5%;
    margin-bottom: 1%;
    text-shadow:5px 5px 10px black;
    font-weight: 700;
}

.WelcomePanelImage {
    margin-right: 3%;
    margin-top: 3%;
    margin-left: 3%;
    margin-bottom: 3%;
    width: 100px;
    height: 100px; 
    object-fit: cover;
}

@media screen and (min-width: 700px) {
    .WelcomePanelImage {
        margin-right: 3%;
        margin-top: 3%;
        margin-left: 3%;
        margin-bottom: 3%;
        width: 200px;
        height: 200px; 
        object-fit: cover;
    }
}
  
.cardSize {
    width: 100%;
    height: 15%;
}

.employerdash_subtitle_font {
    margin-left: 2%;
    font-family: Lato,sans-serif;
    font-size: 2vmax;
}
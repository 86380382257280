.quizBg {
    background-color: rgb(255, 255, 255);
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
}


.questionPosOutOfTotal {
    color: #93c2ec;
    font-family: Lato,sans-serif;
    font-size: 110%;
    margin-top: 1.5%;
}


.toggleQuestionRight {
    width: 10%;
    height: 20%;
    background: none;
    border: none;
    margin-top: -1%;
}

.toggleQuestionRight:hover {
    border: none;
    background: transparent;
}

.toggleQuestionRight:disabled {
    border: none;
    background: transparent;
}

.toggleQuestionRight:focus {
    box-shadow: none !important;
    background: none;
    border: none;
}

.rightArrow {
    margin-top: -180%;
    width: 5vmax;
   
}

@media screen and (min-width: 500px) {
    .rightArrow {
        margin-top: 0%;
        width: 3.5vmax; 
    }    
}


.rightQuestionBttnRemoved {
    background-color: none;
    color: none;
    border: none;
    display: none;
}


/* RESULTS DISPLAY */
.quizPointInfo {
    font-family: Lato,sans-serif;
}

.resultAnswers {
   
    width: 80%;
    background-color: white;
    margin: 3%;
}


@media screen and (min-width: 1000px) {
    .resultAnswers {
        margin-bottom: 2%;
        margin-top: 3%;
       
    }
}

.quizResultsHeader {
    color: #c33333;
    font-family: Lato,sans-serif;
    font-size: 190%;
    margin-left: 3%;
}

.totalCorrectQuestions {
    color: black;
    font-family: Lato,sans-serif;
}

.totalCorrectPoints {
    color: black;
}

.correctPercentage {
    color: black;
}

.quizHomeBttn {
    background-color: #127ebf;
    color: white;
    font-family: Lato,sans-serif;
    border: 0;
    vertical-align: middle !important;
    margin-top: 2%;
    margin-bottom: 5%;
}

#resultsPageContainer {
    background-color: #ffffff !important;
    margin-top: 0%;
    margin-left: 0;
    height: 90vh;
    width: 100%;

    overflow-y: auto;

}
.leaderboardbg {
    background-color: white;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    padding: 50px;
    
}

@media screen and (max-height: 764px) {
    .leaderboardbg {
        background-image: url("leaderboard_medium.png");
        height: 140%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: fixed;
    }
}

.leaderboardContainer {
    margin-top: 20px;
    margin-bottom: 3px;
    margin-left: 2%;
    height: 746px;
    width: 96%;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color:  #1e5b88 rgba(255, 0, 0, 0);

}


@media screen and (min-width: 1000px) {
    .leaderboardContainer {
        margin-top: 20px;
        margin-bottom: 3px;
        margin-left: 1%;
        height: 720px;
        width: 100%;
        background-color: white;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-color:  #1e5b88 rgba(255, 0, 0, 0);
    
    }
}


@media screen and (max-height: 500px) {
    .leaderboardContainer {
        margin-top: 20px;
        margin-bottom: 3px;
        margin-left: 2%;
        height: 520px;
        width: 96%;
        background-color: white;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-color:  #1e5b88 rgba(255, 0, 0, 0);
    
    }
}


.shadowTab_leaderboard {
    margin-top: 3%;
    width: 320px;
    height: 70px;
    background-color:  white;
    font-size: 80%;
}



@media screen and (min-width: 700px) {
    .shadowTab_leaderboard {
        margin-top: 1%;
        width: 380px;
        height: 80px;
        background-color:  white;
        font-size: 100%;
    }
}


.selection_leaderbaord_container  {
    margin-top: 5%;
    background-color: white !important;
}


.orginization_selection_tab:active {
    background-color: white!important;
}

.all_leaderboard_selection {
    background-color: white;
}

.leaderbaord_pill_font.active {
    background-color:  #cc3333!important;
}

html {
  scroll-behavior: smooth;
}

.dashboard {
  margin-bottom: 1%;
  margin-top: 1%;
  margin-left: .5%;

}

.dash_page {
  background-color: rgb(255, 255, 255);
}

.dash_requiredModules {
  background-color: rgb(255, 255, 255);
}

.dash_moduleDirectories {
  background-color: rgb(219, 224, 237);
}

.dash_topBackdrop {
  background-image: url("dash_welcomebg.png");
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  width: auto;
  height: 45vh;
  animation: dash_fadeIn 1s;
  margin-bottom: 2%;
}

.dash_bottomBackdrop {
  background-image: url("dash_welcomebg.png");
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  padding-top: 55%;
  transform: scaleY(-1);
}


.dash_welcomeDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 18vh;
  animation: dash_moveUpwards 1s;
}

.dash_navDiv {
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: white;
}

.dash_navButtons {
  margin-left: -57.5%;
  margin-top: 20%;
  width: 140%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: center;
}

@media screen and (min-width: 700px) {
  .dash_navButtons {
    padding-top: 20px;
    margin-top: 0%;
    margin-left: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: center;
  }
}

.dash_navButton {
  background-color: #0D6EFD;
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(255, 255, 255);
  font-size: 1.2vmax;
  font-family: Verdana, sans-serif;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  .dash_navButton {
    background-color: #0D6EFD;
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(255, 255, 255);
    font-size: 0.8vmax;
    font-family: Verdana, sans-serif;
    text-align: center;
  }
}

.dash_profilePicture {
  margin-right: 1%;
  margin-top: -5%;
  margin-left: 10%;
  width: 150px;
  height: 150px; 
  object-fit: cover;
}

@media screen and (min-width: 700px) {
  .dash_profilePicture {
    margin-right: 1%;
    margin-top: -2%;
    margin-left: 0%;
    width: 200px;
    height: 200px; 
    object-fit: cover;
  }
}

.dash_separatorBegin {
  background-image: url("dash_directoriesbg.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  width: auto;
  height: 25vh;
}

.dash_separatorEnd {
  background-image: url("dash_directoriesbg.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  width: auto;
  height: 25vh;
  margin-top: -20px;
  transform: scaleY(-1);
}

.dash_miniLeaderboard {
  background-color: #D3D3D3;
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
  margin-bottom: 30px;
  width: 80%;
  height: 400px;
}

.dash_welcomeMessageP1 {
  color: white;
  text-shadow: 2px 2px 5px black;
  font-size: 3vmax;
  margin-left: 25px;
  margin-top: 0%;
}

@media screen and (min-width: 1600px) {
  .dash_welcomeMessageP1 {
    color: white;
    text-shadow: 2px 2px 5px black;
    font-size: 3vmax;
    margin-left: 25px;
  }
}

.dash_welcomeMessageP2 {
  color: white;
  text-shadow: 2px 2px 5px black;
  font-size: 3.5vmax;
  margin-top: -15px;
}

.dash_welcomeMessageP3 {
  color: white;
  font-size: 100%;
  font-style: italic;
  margin-top: -5px;
  margin-left: 5px;
  margin-left: 30px;
}

@media screen and (min-width: 1600px) {
  .dash_welcomeMessageP3 {
    color: white;
    font-size: 100%;
    font-style: italic;
    margin-top: -5px;
    margin-left: 5px;
    margin-left: 30px;
  }
}
.dash_h1Style {
  color: rgb(23, 77, 125);
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 2.5vmax;
  text-align: center;
}

.dash_pStyle {
  color: rgb(0, 0, 0);
  font-size: 1vmax;
  text-align: center;
}

.dash_leaderboardLink {
  color: white;
  text-shadow: 2px 2px 5px black;
  text-align: right;
}

.go_to_leader_board_bttn {
  margin-top: 3%;
  margin-bottom: 5%;
  background-color: #0D6EFD;
  color: rgb(255, 255, 255);
}


@media screen and (min-width: 1600px) {
  .go_to_leader_board_bttn {
    margin-top: 0%;
    margin-bottom: 3%;
    background-color: #0D6EFD;
    color: rgb(255, 255, 255);
  }
  
}

@keyframes dash_fadeIn { 
  from { opacity:0; } 
  to { opacity:1; } 
}

@keyframes dash_moveUpwards {
  from { padding-top: 24vh; } 
  to { padding-top: 18vh; }   
}
.settingsProfilePicture {
    margin-right: 1%;
    margin-top: -5%;
    margin-left: 10%;
    width: 150px;
    height: 150px; 
    object-fit: cover;

}

.updateProfilePicture {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 10%;
    height: 10%;
    object-fit: cover;

}

.selectionBox {
    width: 100%;
    background-color: #e4e4e4 !important;
    border-radius: 10px;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 10px;

}

.settingsRow {
    background-color: #ffffffdf !important;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.settingSpacing {
    padding: 10px;
}

.selectedSetting {
    margin-top: 5%;
    background-color: #ffffff !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.selectedSetting.active {
    background-color: #1E5B88 !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}


.unselectedSetting {
    margin-top: 4%;
    background-color: #ffffff !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.unselectedSetting.active {
    margin-top: 4%;
    background-color: #1E5B88 !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.settingsContentPaneContainer {
    margin-top: 1%;
    margin-bottom: 2%;
}

.usernameText { 
    font-family: Lato,sans-serif;
    font-size: 140%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.usernameControl {
    border: #d8dae3;
}


.emailText {
    font-family: Lato,sans-serif;
    font-size: 140%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.emailControl {
    border: #d8dae3;
}


.emailInput {
    font-family: Lato,sans-serif;
    font-size: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.usernameInput {
    font-family: Lato,sans-serif;
    font-size: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.dropShadow {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
    color: black;
}

.shadowTab {
    margin-left: 20%;
    margin-right: 20%;
    height: 17.5vmin;
 
}

.marginTop {
    margin-top: 1%;
}

.edit-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.settingsWideButton {
    background-color: #cc3333;
    color: white !important;
    border: none;
    font-size: 100%;
    width: 20%;
    margin-top: 1%;
    margin-bottom: 2%;
}

.settingsWideButton:hover {
    border: none;
    background-color: #da3e3e;
    color: #d8dae3;
}

.settingsWideButton:focus {
    border: none;
    background-color: #da3e3e;
}

.changePasswordText {
    font-family: Lato,sans-serif;
    font-size: 90%;
}



.padding {
    padding: 3rem !important
}

.user-card-full {
    overflow: hidden;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69,90,100,0.08);
    box-shadow: 0 1px 20px 0 rgba(69,90,100,0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px;
    background: linear-gradient(to top, #6ea7d9, 
    #1c99e6);
}

.bg-c-lite-green {
        background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px){
p {
    font-size: 14px;
}
}


.emp-profile{
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: #fff;
}
.profile-img{
    text-align: center;
}

.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}
.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}
.profile-head h5{
    color: #333;
}
.profile-head h6{
    color: #0062cc;
}
.profile-edit-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}
.proile-rating{
    font-size: 12px;
    color: #818182;
    margin-top: 5%;
}
.proile-rating span{
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}
.profile-head .nav-tabs{
    margin-bottom:5%;
}
.profile-head .nav-tabs .nav-link{
    font-weight:600;
    border: none;
}
.profile-head .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid #0062cc;
}
.profile-work{
    padding: 5%;
    margin-top: -15%;
    border : 1px solid #e9ecef;
}
.profile-work p{
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}
.profile-work a{
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}
.profile-work ul{
    list-style: none;
}
.profile-tab label{
    font-weight: 600;
}
.profile-tab p{
    font-weight: 600;
    color: #0062cc;
}

.companyBanner {
    height: 125px;
}
.Learning_Manager_Invite_Request_Card {
    margin-top: .5%;
    margin-bottom: .5%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 10px 10px 10px 10px;
    background-color: #d8dae3;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 90%;
    color: black;
}

.register_header_add {
    margin-top: 3%;
    margin-left: 3%;
    font-size: 130%;
    text-shadow: 2px 2px 5px gray;
}

.register_header_add_learning_module {
    margin-top: 3%;
    margin-left: 3%;
    font-size: 130%;
    text-shadow: 2px 2px 5px gray;
}


@media screen and (min-width: 700px) {
    .register_header_add_learning_module {
        margin-top: 3%;
        margin-left: 3%;
        font-size: 200%;
        text-shadow: 2px 2px 5px gray;
    }
}

.empolyee_invite_subtitle {
    margin-top: .1%;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 100%;
    width: 90%;
}

@media screen and (min-width: 700px) {
    .empolyee_invite_subtitle {
        margin-top: .1%;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 130%;
        width: 96%;
    }
}


.invite_subtitle_learning_manager {
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 100%;
    width: 90%;
}

@media screen and (min-width: 700px) {
    .invite_subtitle_learning_manager {
        margin-top: .1%;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 130%;
        width: 96%;
    }
}

.learningModule_add_module_form_container {
    width: 100% !important;
    
}

.learningModule_add_module_form {
    margin-top: 2%;
    width: 95% !important;
}

.create_button_learning_manager {
    width: 85%;
    font-family: Lato,sans-serif;
    margin-top: 3%;
    margin-bottom: 2%;
    border: none;
    font-size: 80%;
}

@media screen and (min-width: 700px) {
    .create_button_learning_manager {
        width: 85%;
        font-family: Lato,sans-serif;
        margin-top: 3%;
        margin-bottom: 2%;
        border: none;
        font-size: 120%;
    }
}
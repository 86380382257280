.margin_bottom_learning_manager{
    margin-bottom: 3.5%;

}
@media screen and (min-width: 700px) {
    .margin_bottom_learning_manager {
        margin-bottom: 1.5%;
    }
}

.Current_Modules_Learning_Manager {
    background-color: #d8dae3;
    color: black;
    width: 100%;
    margin-top: .5%;
    margin-bottom: .5%;
}


.employee_learning_modules_manager_title {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 2.5%;
    font-size: 130%;
    text-shadow: 2px 2px 5px gray;
}

@media screen and (min-width: 700px) {
    .employee_learning_modules_manager_title {
        margin-top: .5%;
        margin-bottom: .5%;
        margin-left: 1.5%;
        font-size: 200%;
        text-shadow: 2px 2px 5px gray;
    }
}

@media screen and (min-width: 700px) {
    .Current_Modules_Learning_Manager  {
        background-color: #d8dae3;
        color: black;
        width: 100%;
        margin-top: 0%;
        margin-bottom: .5%;
    }
}

.Learning_Manager_Card_Header  {
    margin-top: 0%;
    margin-right: 3%;
    margin-bottom: .1%;
    margin-left: 1%;
    width: 98%;
    color: black;
}

.Learning_Manager_Card_Values_learning_manager {
    font-size: 80%;
}

@media screen and (min-width: 700px) { 
    .Learning_Manager_Card_Values_learning_manager {
        font-size: 120%;
    }
}

.learning_module_date_picker {
    background-color: white !important; 
    color: black !important;
    border: none !important;
  
}

.react-date-picker, .react-date-picker *:before, .react-date-picker *:after {
    width: 100%;
}

@media screen and (min-width: 700px) { 
    .react-date-picker {
        width: auto;
    }
}

.react-date-picker__wrapper {
    border: none !important;
    width: 100% !important;
}

.react-date-picker__clear-button svg {
    stroke: #cc3333 !important;
    width: 100%;
}

@media screen and (min-width: 700px) { 
    .react-date-picker__clear-button svg {
        stroke: #cc3333 !important;
        width: 100%;
    }
}

.react-date-picker__clear-button svg:hover {
    stroke: #c33333 !important;
}

.react-date-picker__calendar-button svg {
    width: 100%;
    stroke: #0b5ed7 !important;
}

@media screen and (min-width: 700px) { 
    .react-date-picker__calendar-button svg {
        stroke: #0b5ed7 !important;
        width: 100%;
    }
}


.react-date-picker__inputGroup__input {
    color: black !important; 
    border: none;
    font-family: Lato,sans-serif;
}

.date_picker_learning_manager_col {
    font-size: 80%;
}

@media screen and (min-width: 700px) { 
    .date_picker_learning_manager_col {
        font-size: 120%;
    }
}


.Remove_Button_learning_manager_col {
    font-size: 80%;
}

@media screen and (min-width: 700px) { 
    .Remove_Button_learning_manager_col {
        font-size: 120%;
    }
}

.learning_manager_card {
    margin-top: .7%;
    margin-left: 1%;
    margin-right: .5%;
    margin-bottom: .5%;
    border-radius: 10px 10px 10px 10px;
    background-color: #1e5b88;
    width: 98%;
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 90%;
    color: white !important;
}

.Learning_Manager_Card_Values_mini {
    margin-top: 2%;
    margin-left: 10%;
    margin-bottom: 1;
    font-size: 90%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

@media screen and (min-width: 700px) {
    .Learning_Manager_Card_Values_mini {
        margin-top: .5%;
        margin-bottom: 1;
        font-size: 120%;
        margin-left: 10%;
        width: 100%;
        overflow:hidden !important;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
    }
}

.LearningManagerInRowButton_confirm {
    margin-top: 2%;
}


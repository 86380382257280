.earned {
    width: 125px;
    transition: all .1s ease-in-out;
}

.earned:hover {
    transform: scale(1.05);
}

.unearned {
    width: 125px;
    filter: grayscale(100%)
}

.badgeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.indivBadge {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 25px;
    margin-right: 25px;
}

.badgeTitle {
    text-align: center;
}
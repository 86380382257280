.uvs-left, .uvs-right {
    border-radius: 10px 10px 10px 10px;
    border: white;
}


.font {
    color: white;
    font-family: Lato,sans-serif;
    text-decoration: none;
}

.font:hover{
    color: #d8dae3;
}



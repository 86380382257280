.quizSubmitBttn {
    background-color: #127ebf;
    color: white;
    font-family: Lato,sans-serif;
    border: 0;
    margin-top: 5vmax;
    margin-bottom: 5%;
    font-size: 130%;
}

.quizSubmitBttn:hover {
    background-color: #127ebf;
    color: #d8dae3;
    font-family: Lato,sans-serif;
    border: 0;
}

.quizSubmitBttnRemoved {
    background-color: none;
    color: none;
    border: none;
    display: none;
}